import React from 'react'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Main from '../components/Main'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, form } from '../content/paperliner'
import { useLangContext } from '../context/lang.context'
import FormMail from '../components/FormMail'

const Paperliner = () => {
  const { lang } = useLangContext()

  const {
    image,
    paperliner1,
    paperliner2,
    paperliner3,
    paperliner4,
    paperliner5,
  } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/paperliner.jpeg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      paperliner1: file(
        absolutePath: { regex: "/images/paperliner/paperliner1.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      paperliner2: file(
        absolutePath: { regex: "/images/paperliner/paperliner2.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      paperliner3: file(
        absolutePath: { regex: "/images/paperliner/paperliner3.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      paperliner4: file(
        absolutePath: { regex: "/images/paperliner/paperliner4.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      paperliner5: file(
        absolutePath: { regex: "/images/paperliner/paperliner5.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  const images = {
    paperliner1,
    paperliner2,
    paperliner3,
    paperliner4,
    paperliner5,
  }

  const newMain = {
    ...main,
    body: main.body.map((bodyItem) => ({
      ...bodyItem,
      texts: bodyItem.texts.map((textItem) => ({
        ...textItem,
        image: textItem.image && images[textItem.image]?.childImageSharp,
      })),
    })),
  }

  const buttonSecond = {
    text: {
      en: 'Ask for an offer',
      es: 'Pide una oferta',
      ro: 'Cereți o ofertă',
    },
    link: '/paperliner/#form',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/paperliner.jpg'}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, buttonSecond }}
        image={image.childImageSharp}
        h={2}
        logo
      />
      <Main data={newMain} h={1} />
      <FormMail data={form} template='OmidaLogsiticsPaperliner' />
      <div css={{ paddingBottom: '4rem' }} />
    </Layout>
  )
}

export default Paperliner
